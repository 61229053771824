import { render, staticRenderFns } from "./AddOrEditClass.vue?vue&type=template&id=53ff9279&scoped=true&"
import script from "./AddOrEditClass.vue?vue&type=script&lang=js&"
export * from "./AddOrEditClass.vue?vue&type=script&lang=js&"
import style0 from "./AddOrEditClass.vue?vue&type=style&index=0&id=53ff9279&lang=scss&scoped=true&"
import style1 from "./AddOrEditClass.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53ff9279",
  null
  
)

export default component.exports