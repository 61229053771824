<template>
  <b-form>
    <ValidationObserver v-slot="{ invalid }">
      <div class="form-space">
        <div class="f2f-schedule-heading mt-4 mb-4">
          <div>{{ isEdit ? "Edit" : "New" }} class</div>
          <div v-if="isEdit">
            <b-button
              variant="link"
              @click="cancelClass"
              class="cancelButton mr-4"
              >Cancel</b-button
            >
            <b-button
              variant="success"
              :disabled="invalid || submitted"
              @click="handleUpdate"
            >
              <b-spinner v-if="submitted" small type="grow"></b-spinner>
              Save
            </b-button>
          </div>
        </div>
        <div
          class="registeredCount-div"
          v-if="classObj.registered_students_count > 0"
        >
          <p class="registeredCount-div-text">
            <span class="mr-3 ml-2"
              ><img src="@/assets/stop.png" alt=""
            /></span>
            This class cannot be fully edited since it has users registered to
            the class.
          </p>
        </div>

        <b-row class="mb-4">
          <b-col cols="6">
            <div class="f2f-schedule-text">Class title *</div>
            <div class="f2f-schedule-span">Add a name to your class</div>
          </b-col>
          <b-col cols="6">
            <ValidationProvider
              name="Title"
              rules="required"
              v-slot="{ errors }"
            >
              <f2f-input
                v-model="classObj.title"
                placeholder="Course name by default"
                :error="errors[0]"
              />
              <span class="f2f-validation-text">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-col>
        </b-row>

        <b-row class="mb-4">
          <b-col cols="6">
            <div class="f2f-schedule-text">Class description</div>
            <div class="f2f-schedule-span">
              Write a short description of this class or add any notes.
            </div>
          </b-col>
          <b-col cols="6">
            <f2f-textarea
              v-model="classObj.description"
              placeholder="Write a description"
              rows="4"
            />
          </b-col>
        </b-row>

        <b-row class="mb-4">
          <b-col cols="6">
            <div class="f2f-schedule-text">Class schedule *</div>
            <div class="f2f-schedule-span">
              Dates that control first to last day the class happening
            </div>
          </b-col>
          <b-col cols="6">
            <b-row>
              <b-col cols="6">
                <ValidationProvider
                  name="Date"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <date-picker
                    class="datepicker"
                    v-model="dateRange"
                    range
                    type="date"
                    :editable="false"
                    value-type="YYYY-MM-DD"
                    :disabled-date="disabledDate"
                    :default-value="new Date()"
                    placeholder="DD/MM/YY - DD/MM/YY"
                    :disabled="classObj.registered_students_count > 0"
                  ></date-picker>
                  <span class="f2f-validation-text">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-col>
              <b-col cols="6">
                <ValidationProvider
                  name="Time"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <date-picker
                    class="datepicker"
                    v-model="timeRange"
                    type="time"
                    range
                    :editable="false"
                    :show-second="false"
                    format="hh:mm A"
                    placeholder="09:00 AM - 05:00 PM"
                    :disabled="classObj.registered_students_count > 0"
                    :time-picker-options="{
                      start: '09:00',
                      step: '00:15',
                      end: '17:00',
                      format: 'hh:mm A'
                    }"
                  ></date-picker>
                  <span class="f2f-validation-text">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="mb-4">
          <b-col cols="6">
            <div class="f2f-schedule-text">Select country *</div>
            <div class="f2f-schedule-span">Select from the list</div>
          </b-col>
          <b-col cols="6">
            <ValidationProvider
              name="Country"
              rules="required"
              v-slot="{ errors }"
            >
              <f2f-select
                v-model="classObj.country"
                :options="getAllCountry"
                :error="errors[0]"
                placeholder="Country"
                :disabled="classObj.registered_students_count > 0"
              />

              <span class="f2f-validation-text">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-col>
        </b-row>

        <b-row class="mb-4">
          <b-col cols="6">
            <div class="f2f-schedule-text">Select Location *</div>
            <div class="f2f-schedule-span">Select from the list</div>
          </b-col>
          <b-col cols="6">
            <ValidationProvider
              name="Location"
              rules="required"
              v-slot="{ errors }"
            >
              <f2f-select
                v-model="classObj.location"
                text="Location"
                :options="getAllLocation"
                :disabled="
                  !classObj.country || classObj.registered_students_count > 0
                "
                placeholder="Location"
              />

              <span class="f2f-validation-text">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-col>
        </b-row>

        <b-row class="mb-4">
          <b-col cols="6">
            <div class="f2f-schedule-text">Select Facility *</div>
            <div class="f2f-schedule-span">Select from the list</div>
          </b-col>
          <b-col cols="6">
            <ValidationProvider
              name="Facility"
              rules="required"
              v-slot="{ errors }"
            >
              <f2f-select
                v-model="classObj.facility"
                text="Facility"
                :options="getAllFacility"
                :disabled="
                  !classObj.location || classObj.registered_students_count > 0
                "
                placeholder="Facility"
              />

              <span class="f2f-validation-text">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-col>
        </b-row>

        <b-row class="mb-4">
          <b-col cols="6">
            <div class="f2f-schedule-text">Select Room *</div>
            <div class="f2f-schedule-span">Select from the list</div>
          </b-col>
          <b-col cols="6">
            <ValidationProvider
              name="Room"
              rules="required"
              v-slot="{ errors }"
            >
              <f2f-select
                v-model="classObj.room"
                text="Room"
                :options="getAllRooms"
                :disabled="
                  !classObj.facility || classObj.registered_students_count > 0
                "
                placeholder="Room"
              /><span class="f2f-validation-text">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-col>
        </b-row>

        <b-row class="mb-4">
          <b-col cols="6">
            <div class="f2f-schedule-text">Minimum and maximum Capacity *</div>
            <div class="f2f-schedule-span">Input a valid number</div>
          </b-col>
          <b-col cols="6">
            <b-row>
              <b-col cols="6">
                <ValidationProvider
                  name="Minimum capacity"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <f2f-input
                    placeholder="Minimum"
                    v-model="classObj.min_capacity"
                    type="number"
                    min="1"
                    :max="allowed_capacity"
                    :disabled="!classObj.room"
                  />
                  <span class="f2f-validation-text">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-col>
              <b-col cols="6">
                <ValidationProvider
                  name="Maximum capacity"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <f2f-input
                    placeholder="Maximum"
                    v-model="classObj.max_capacity"
                    type="number"
                    :min="classObj.min_capacity"
                    :max="allowed_capacity"
                    :disabled="!classObj.room"
                  />
                  <span class="f2f-validation-text">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="mb-4">
          <b-col cols="6">
            <div class="f2f-schedule-text">Class co-ordinator</div>
            <div class="f2f-schedule-span">
              Class will be shared with the co-ordinator
            </div>
          </b-col>
          <b-col cols="6">
            <f2f-select
              v-model="classObj.coordinator"
              text="Coordinator"
              :options="getAllCoordinators"
              placeholder="Select from list"
            />
          </b-col>
        </b-row>

        <b-row class="mb-4">
          <b-col cols="6">
            <div class="f2f-schedule-text">Attach to existing unit?</div>
            <div class="f2f-schedule-span">
              Attaching units to classes allows you to share content with your
              students
            </div>
          </b-col>
          <b-col cols="6">
            <f2f-radio v-model="attachUnit" :options="attachUnitOptions" />
          </b-col>
        </b-row>

        <div v-if="showUnit">
          <b-row class="mb-4">
            <b-col cols="6">
              <div class="f2f-schedule-text">Select Module</div>
              <div class="f2f-schedule-span">Select from the list</div>
            </b-col>
            <b-col cols="6">
              <ValidationProvider
                name="Module"
                rules="required"
                v-slot="{ errors }"
              >
                <f2f-select
                  v-model="classObj.course_module_key"
                  :options="getAllModules"
                  :error="errors[0]"
                  placeholder="Module"
                />
                <span class="f2f-validation-text">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-col>
          </b-row>

          <b-row class="mb-4">
            <b-col cols="6">
              <div class="f2f-schedule-text">Select Chapter</div>
              <div class="f2f-schedule-span">Select from the list</div>
            </b-col>
            <b-col cols="6">
              <ValidationProvider
                name="Chapter"
                rules="required"
                v-slot="{ errors }"
              >
                <f2f-select
                  :options="getAllChapters"
                  v-model="classObj.course_chapter_key"
                  :error="errors[0]"
                  :disabled="!classObj.course_module_key"
                  placeholder="Chapter"
                />

                <span class="f2f-validation-text">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-col>
          </b-row>

          <b-row class="mb-4">
            <b-col cols="6">
              <div class="f2f-schedule-text">Select Unit</div>
              <div class="f2f-schedule-span">Select from the list</div>
            </b-col>
            <b-col cols="6">
              <ValidationProvider
                name="Unit"
                rules="required"
                v-slot="{ errors }"
              >
                <f2f-select
                  v-model="classObj.course_unit_key"
                  placeholder="Unit"
                  :options="getAllUnits"
                  :error="errors[0]"
                  :disabled="!classObj.course_chapter_key"
                />

                <span class="f2f-validation-text">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-col>
          </b-row>
        </div>

        <b-row class="mb-3" v-if="!isEdit">
          <b-col cols="6"> </b-col>
          <b-col cols="6">
            <div class="float-right">
              <b-button
                variant="link"
                @click="cancelClass"
                class="cancelButton mr-4"
                >Cancel</b-button
              >
              <b-button
                variant="success"
                @click="handleSubmit"
                :disabled="invalid || submitted"
              >
                <b-spinner v-if="submitted" small type="grow"></b-spinner>
                Create
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
    </ValidationObserver>

    <f2f-modal
      :display="confirmEdit"
      @hide="onEditConfirm"
      type="warning"
      title="Are you sure you want to change the dates for this class?"
      body="Editing class dates will affect previous settings like instructor assignment to session and all sessions
      information that were previously created, those will be removed and new session will be generated"
    />

    <f2f-modal
      :display="onError"
      @hide="onError = false"
      type="danger"
      :title="errortitle"
      :body="error"
    />
  </b-form>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { required, max, digits } from "vee-validate/dist/rules";
import { extend } from "vee-validate";
import { mapGetters } from "vuex";
import * as moment from "moment";

extend("required", required);
extend("max", max);
extend("digits", digits);

const timeFormat = "HH:mm:ss";
export default {
  props: {
    classId: {
      type: Number,
      default: -1
    }
  },
  components: { DatePicker },

  data() {
    return {
      errortitle: "",
      error: "",
      onError: false,
      confirmEdit: false,
      timeRange: [],
      dateRange: [],
      date: null,
      showAlert: false,
      submitted: false,
      attachUnit: "0",
      doReset: true,
      allowed_capacity: "0",
      classObj: {
        dateRange: {
          start_date_at: "",
          end_date_at: ""
        },
        timeRange: {
          start_time_at: "",
          end_time_at: ""
        },
        title: "",
        description: "",
        country: null,
        location: null,
        room: null,
        max_capacity: "",
        min_capacity: "",
        coordinator: null,
        facility: null,
        course_module_key: null,
        course_chapter_key: null,
        course_unit_key: null
      },
      attachUnitOptions: [
        { value: "0", text: "No" },
        { value: "1", text: "Yes" }
      ],
      editClassTimings: {
        timeRange: [],
        dateRange: []
      }
    };
  },
  computed: {
    ...mapGetters([
      "getAllCountry",
      "getAllLocation",
      "getAllFacility",
      "getAllRooms",
      "getCreateClass",
      "getAllModules",
      "getAllChapters",
      "getAllUnits",
      "getAllCoordinators"
    ]),
    showUnit() {
      return this.attachUnit == "1";
    },
    isEdit() {
      return this.classId != -1;
    }
  },

  watch: {
    ["classObj.country"]: function (val) {
      this.$store.dispatch("getLocationDetails", val);
      if (this.doReset) {
        this.classObj.location = null;
        this.classObj.facility = null;
        this.classObj.room = null;
      }
    },
    ["classObj.location"]: function (val) {
      if (val) {
        this.$store.dispatch("getFacilitiesDetails", val);
        if (this.doReset) {
          this.classObj.facility = null;
          this.classObj.room = null;
        }
      }
    },
    ["classObj.facility"]: function (val) {
      if (val) {
        this.$store.dispatch("getRoomsDetails", val);
        if (this.doReset) {
          this.classObj.room = null;
        }
      }
    },
    ["classObj.room"]: function (val) {
      if (val) {
        if (this.doReset) {
          const room = (this.$store.getters.getAllRooms || []).find(
            (r) => r.value == val
          );
          this.allowed_capacity = room?.obj?.capacity || 0;
          this.classObj.min_capacity = "";
          this.classObj.max_capacity = "";
        }
      }
    },
    ["classObj.course_module_key"]: function (val) {
      if (val) {
        this.$store.dispatch("getChapters", val);
      }
      if (this.doReset) {
        this.classObj.course_chapter_key = null;
        this.classObj.course_unit_key = null;
      }
    },
    ["classObj.course_chapter_key"]: function (val) {
      if (val) {
        this.$store.dispatch("getUnits", val);
      }
      if (this.doReset) {
        this.classObj.course_unit_key = null;
      }
    }
  },
  async mounted() {
    if (this.isEdit) {
      const classResponse = await this.$store.dispatch("getClassDetails", {
        courseId: this.$route.params.id,
        classId: this.classId
      });
      if (classResponse && classResponse.data) {
        this.doReset = false;
        const info = classResponse.data;
        this.classObj = {
          ...info,
          country: info.country?.id?.toString(),
          location: info.location?.id?.toString(),
          facility: info.facility?.id?.toString(),
          room: info.room?.id?.toString(),
          coordinator: info.coordinator?.id?.toString()
        };
        this.dateRange = [
          this.formatDate(new Date(info.start_date_at)),
          this.formatDate(new Date(info.end_date_at))
        ];
        this.timeRange = [
          moment(info.start_time_at, timeFormat).toDate(),
          moment(info.end_time_at, timeFormat).toDate()
        ];
        this.editClassTimings.dateRange = [
          this.formatDate(new Date(info.start_date_at)),
          this.formatDate(new Date(info.end_date_at))
        ];
        this.editClassTimings.timeRange = [
          moment(info.start_time_at, timeFormat).toDate(),
          moment(info.end_time_at, timeFormat).toDate()
        ];
        if (info.course_module_key && info.course_chapter_key) {
          this.attachUnit = "1";
        }

        const roomResponse = await this.$store.dispatch(
          "getRoomsDetails",
          this.classObj.facility
        );
        if (roomResponse.data) {
          const room = (roomResponse.data || []).find(
            (r) => r.id == this.classObj.room
          );
          this.allowed_capacity = room?.capacity || 0;
        }

        // .5 second delay so that watcher are executed
        setTimeout(() => (this.doReset = true), 500);
      }
    } else {
      try {
        const courseResponse = await this.$store.dispatch(
          "getCourseDetails",
          this.$route.params.id
        );
        this.classObj.title = courseResponse?.data?.data?.title;
      } catch (err) {
        console.log("Get class info error");
      }
    }
    this.$store.dispatch("getCountryDetails");
    this.$store.dispatch("getModules", this.$route.params.id);
    this.$store.dispatch("getCoordinators", this.$route.params.id);
  },

  methods: {
    async handleUpdate() {
      let isDifferent = false;
      if (
        this.editClassTimings.dateRange[0] != this.dateRange[0] ||
        this.editClassTimings.dateRange[1] != this.dateRange[1]
      ) {
        isDifferent = true;
      }
      if (
        (this.formatDateByPattern(
          this.editClassTimings.timeRange[0],
          timeFormat
        ) != this.formatDateByPattern(this.timeRange[0], timeFormat) ||
          this.formatDateByPattern(
            this.editClassTimings.timeRange[1],
            timeFormat
          ) != this.formatDateByPattern(this.timeRange[1]),
        timeFormat)
      ) {
        isDifferent = true;
      }
      if (isDifferent) {
        this.confirmEdit = true;
      } else {
        this.handleSubmit();
      }
    },
    async onEditConfirm(userAction) {
      this.confirmEdit = false;
      if (userAction) {
        this.handleSubmit();
      }
    },
    isInvalidCapacity() {
      if (
        this.classObj.min_capacity > this.classObj.max_capacity ||
        this.classObj.min_capacity > this.allowed_capacity ||
        this.classObj.max_capacity > this.allowed_capacity
      ) {
        this.errortitle =
          "Sorry, we are unable to " +
          (this.isEdit ? "Edit" : "Add") +
          " the class.";
        this.error =
          "Please make sure the minimum capacity of the class is greater than 1, and the maximum capacity is lower then " +
          this.allowed_capacity +
          ". Minimum capacity must be lower than maximum capacity number.";
        this.onError = true;
        return true;
      }
      return false;
    },
    async handleSubmit() {
      if (this.isInvalidCapacity()) {
        return;
      }

      this.submitted = true;
      const classObj = {
        ...this.classObj
      };
      classObj.start_date_at = this.dateRange[0];
      classObj.end_date_at = this.dateRange[1];

      classObj.start_time_at = this.formatDateByPattern(
        this.timeRange[0],
        timeFormat
      );
      classObj.end_time_at = this.formatDateByPattern(
        this.timeRange[1],
        timeFormat
      );

      if (this.attachUnit == "0") {
        delete classObj.course_module_key;
        delete classObj.course_chapter_key;
        delete classObj.course_unit_key;
      }

      if (!classObj.coordinator) {
        delete classObj.coordinator;
      }

      const toPost = {
        courseId: this.$route.params.id,
        classObj
      };

      let actionName = "addClassDetails";
      let message = "New class created successfully!";
      if (this.isEdit) {
        toPost.classId = this.classId;
        actionName = "updateClassDetails";
        message = "Class edited successfully!";
      }
      try {
        await this.$store.dispatch(actionName, toPost);
        this.$store.dispatch("showNotification", { message });
        this.submitted = false;
        if (this.isEdit) {
          this.$emit("onEdit", true);
        } else {
          this.$router.push({ name: "schedule" });
        }
      } catch (err) {
        this.errortitle = "Unable to " + (this.isEdit ? "Edit" : "Add");
        this.submitted = false;
        if (err?.response?.data) {
          this.error = Object.keys(err?.response?.data)
            .map((key) => err?.response?.data[key][0])
            .join(",");
          this.onError = true;
        }
      }
    },
    disabledDate(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date < today;
    },
    cancelClass() {
      if (this.isEdit) {
        this.$emit("onEdit", false);
      } else {
        this.$router.push({ name: "schedule" });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.registeredCount-div {
  border: 1px solid #ffaa15;
  box-sizing: border-box;
  border-radius: 10px;
  margin-bottom: 3%;
  height: 40px;
}
.registeredCount-div-text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1px;
  color: #757575;
  padding: 7px;
}
.form-space {
  max-width: 1280px;
  // min-width: 900px;
  margin: 0 auto;
  .f2f-schedule__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 0rem;
    border-bottom: 0.5px solid #e8e8e8;

    .schedule-head__title {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.2px;
      color: #4c4c4c;
    }

    .schedule-head__section {
      font-weight: bold;
      font-size: 38px;
      line-height: 32px;
      letter-spacing: 0.1px;
      color: #333333;
    }
  }
  .f2f-schedule-container {
    background: #ffffff;
    border: 1px solid #b2b2b2;
    box-sizing: border-box;
    .form-space {
      padding: 1rem;
    }
  }

  .f2f-schedule-heading {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height, or 120% */
    display: flex;
    justify-content: space-between;
    align-items: center;
    letter-spacing: 0.1px;

    color: #4c4c4c;
  }
  .f2f-schedule-text {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 24px;
    /* or 150% */

    letter-spacing: 0.1px;

    /* Secondary text */

    color: #52575c;
  }

  .f2f-schedule-span {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    /* or 133% */

    letter-spacing: 0.2px;

    /* Default texts in text field */

    color: #757575;
  }

  .f2f-validation-text {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 18px;
    /* identical to box height, or 164% */

    letter-spacing: 0.2px;

    /* Other/Negative */

    color: #ff4040;
  }

  .f2f-schedule-info {
    .class-info__head {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.1px;
      color: #25282b;
      margin-bottom: 6px;
    }
    .class-info__text {
      font-family: Roboto;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.1px;
      color: #52575c;
    }
  }
}
.has-success {
  color: green;
}

.cancelButton {
  color: #a0a4a8;
}

button:disabled,
button[disabled] {
  border-radius: 4px;
  background-color: #717171 !important;
  border-color: #717171 !important;
}
</style>
<style lang="scss">
.datepicker {
  width: 100%;

  input {
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 16px;
    height: auto;
    background: #fafafa;
  }
}
</style>
