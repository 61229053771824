var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"form-space"},[_c('div',{staticClass:"f2f-schedule-heading mt-4 mb-4"},[_c('div',[_vm._v(_vm._s(_vm.isEdit ? "Edit" : "New")+" class")]),(_vm.isEdit)?_c('div',[_c('b-button',{staticClass:"cancelButton mr-4",attrs:{"variant":"link"},on:{"click":_vm.cancelClass}},[_vm._v("Cancel")]),_c('b-button',{attrs:{"variant":"success","disabled":invalid || _vm.submitted},on:{"click":_vm.handleUpdate}},[(_vm.submitted)?_c('b-spinner',{attrs:{"small":"","type":"grow"}}):_vm._e(),_vm._v(" Save ")],1)],1):_vm._e()]),(_vm.classObj.registered_students_count > 0)?_c('div',{staticClass:"registeredCount-div"},[_c('p',{staticClass:"registeredCount-div-text"},[_c('span',{staticClass:"mr-3 ml-2"},[_c('img',{attrs:{"src":require("@/assets/stop.png"),"alt":""}})]),_vm._v(" This class cannot be fully edited since it has users registered to the class. ")])]):_vm._e(),_c('b-row',{staticClass:"mb-4"},[_c('b-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"f2f-schedule-text"},[_vm._v("Class title *")]),_c('div',{staticClass:"f2f-schedule-span"},[_vm._v("Add a name to your class")])]),_c('b-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('f2f-input',{attrs:{"placeholder":"Course name by default","error":errors[0]},model:{value:(_vm.classObj.title),callback:function ($$v) {_vm.$set(_vm.classObj, "title", $$v)},expression:"classObj.title"}}),_c('span',{staticClass:"f2f-validation-text"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-row',{staticClass:"mb-4"},[_c('b-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"f2f-schedule-text"},[_vm._v("Class description")]),_c('div',{staticClass:"f2f-schedule-span"},[_vm._v(" Write a short description of this class or add any notes. ")])]),_c('b-col',{attrs:{"cols":"6"}},[_c('f2f-textarea',{attrs:{"placeholder":"Write a description","rows":"4"},model:{value:(_vm.classObj.description),callback:function ($$v) {_vm.$set(_vm.classObj, "description", $$v)},expression:"classObj.description"}})],1)],1),_c('b-row',{staticClass:"mb-4"},[_c('b-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"f2f-schedule-text"},[_vm._v("Class schedule *")]),_c('div',{staticClass:"f2f-schedule-span"},[_vm._v(" Dates that control first to last day the class happening ")])]),_c('b-col',{attrs:{"cols":"6"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker',{staticClass:"datepicker",attrs:{"range":"","type":"date","editable":false,"value-type":"YYYY-MM-DD","disabled-date":_vm.disabledDate,"default-value":new Date(),"placeholder":"DD/MM/YY - DD/MM/YY","disabled":_vm.classObj.registered_students_count > 0},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}}),_c('span',{staticClass:"f2f-validation-text"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"Time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker',{staticClass:"datepicker",attrs:{"type":"time","range":"","editable":false,"show-second":false,"format":"hh:mm A","placeholder":"09:00 AM - 05:00 PM","disabled":_vm.classObj.registered_students_count > 0,"time-picker-options":{
                    start: '09:00',
                    step: '00:15',
                    end: '17:00',
                    format: 'hh:mm A'
                  }},model:{value:(_vm.timeRange),callback:function ($$v) {_vm.timeRange=$$v},expression:"timeRange"}}),_c('span',{staticClass:"f2f-validation-text"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1),_c('b-row',{staticClass:"mb-4"},[_c('b-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"f2f-schedule-text"},[_vm._v("Select country *")]),_c('div',{staticClass:"f2f-schedule-span"},[_vm._v("Select from the list")])]),_c('b-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('f2f-select',{attrs:{"options":_vm.getAllCountry,"error":errors[0],"placeholder":"Country","disabled":_vm.classObj.registered_students_count > 0},model:{value:(_vm.classObj.country),callback:function ($$v) {_vm.$set(_vm.classObj, "country", $$v)},expression:"classObj.country"}}),_c('span',{staticClass:"f2f-validation-text"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-row',{staticClass:"mb-4"},[_c('b-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"f2f-schedule-text"},[_vm._v("Select Location *")]),_c('div',{staticClass:"f2f-schedule-span"},[_vm._v("Select from the list")])]),_c('b-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"Location","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('f2f-select',{attrs:{"text":"Location","options":_vm.getAllLocation,"disabled":!_vm.classObj.country || _vm.classObj.registered_students_count > 0,"placeholder":"Location"},model:{value:(_vm.classObj.location),callback:function ($$v) {_vm.$set(_vm.classObj, "location", $$v)},expression:"classObj.location"}}),_c('span',{staticClass:"f2f-validation-text"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-row',{staticClass:"mb-4"},[_c('b-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"f2f-schedule-text"},[_vm._v("Select Facility *")]),_c('div',{staticClass:"f2f-schedule-span"},[_vm._v("Select from the list")])]),_c('b-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"Facility","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('f2f-select',{attrs:{"text":"Facility","options":_vm.getAllFacility,"disabled":!_vm.classObj.location || _vm.classObj.registered_students_count > 0,"placeholder":"Facility"},model:{value:(_vm.classObj.facility),callback:function ($$v) {_vm.$set(_vm.classObj, "facility", $$v)},expression:"classObj.facility"}}),_c('span',{staticClass:"f2f-validation-text"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-row',{staticClass:"mb-4"},[_c('b-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"f2f-schedule-text"},[_vm._v("Select Room *")]),_c('div',{staticClass:"f2f-schedule-span"},[_vm._v("Select from the list")])]),_c('b-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"Room","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('f2f-select',{attrs:{"text":"Room","options":_vm.getAllRooms,"disabled":!_vm.classObj.facility || _vm.classObj.registered_students_count > 0,"placeholder":"Room"},model:{value:(_vm.classObj.room),callback:function ($$v) {_vm.$set(_vm.classObj, "room", $$v)},expression:"classObj.room"}}),_c('span',{staticClass:"f2f-validation-text"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-row',{staticClass:"mb-4"},[_c('b-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"f2f-schedule-text"},[_vm._v("Minimum and maximum Capacity *")]),_c('div',{staticClass:"f2f-schedule-span"},[_vm._v("Input a valid number")])]),_c('b-col',{attrs:{"cols":"6"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"Minimum capacity","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('f2f-input',{attrs:{"placeholder":"Minimum","type":"number","min":"1","max":_vm.allowed_capacity,"disabled":!_vm.classObj.room},model:{value:(_vm.classObj.min_capacity),callback:function ($$v) {_vm.$set(_vm.classObj, "min_capacity", $$v)},expression:"classObj.min_capacity"}}),_c('span',{staticClass:"f2f-validation-text"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"Maximum capacity","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('f2f-input',{attrs:{"placeholder":"Maximum","type":"number","min":_vm.classObj.min_capacity,"max":_vm.allowed_capacity,"disabled":!_vm.classObj.room},model:{value:(_vm.classObj.max_capacity),callback:function ($$v) {_vm.$set(_vm.classObj, "max_capacity", $$v)},expression:"classObj.max_capacity"}}),_c('span',{staticClass:"f2f-validation-text"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1),_c('b-row',{staticClass:"mb-4"},[_c('b-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"f2f-schedule-text"},[_vm._v("Class co-ordinator")]),_c('div',{staticClass:"f2f-schedule-span"},[_vm._v(" Class will be shared with the co-ordinator ")])]),_c('b-col',{attrs:{"cols":"6"}},[_c('f2f-select',{attrs:{"text":"Coordinator","options":_vm.getAllCoordinators,"placeholder":"Select from list"},model:{value:(_vm.classObj.coordinator),callback:function ($$v) {_vm.$set(_vm.classObj, "coordinator", $$v)},expression:"classObj.coordinator"}})],1)],1),_c('b-row',{staticClass:"mb-4"},[_c('b-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"f2f-schedule-text"},[_vm._v("Attach to existing unit?")]),_c('div',{staticClass:"f2f-schedule-span"},[_vm._v(" Attaching units to classes allows you to share content with your students ")])]),_c('b-col',{attrs:{"cols":"6"}},[_c('f2f-radio',{attrs:{"options":_vm.attachUnitOptions},model:{value:(_vm.attachUnit),callback:function ($$v) {_vm.attachUnit=$$v},expression:"attachUnit"}})],1)],1),(_vm.showUnit)?_c('div',[_c('b-row',{staticClass:"mb-4"},[_c('b-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"f2f-schedule-text"},[_vm._v("Select Module")]),_c('div',{staticClass:"f2f-schedule-span"},[_vm._v("Select from the list")])]),_c('b-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"Module","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('f2f-select',{attrs:{"options":_vm.getAllModules,"error":errors[0],"placeholder":"Module"},model:{value:(_vm.classObj.course_module_key),callback:function ($$v) {_vm.$set(_vm.classObj, "course_module_key", $$v)},expression:"classObj.course_module_key"}}),_c('span',{staticClass:"f2f-validation-text"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-row',{staticClass:"mb-4"},[_c('b-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"f2f-schedule-text"},[_vm._v("Select Chapter")]),_c('div',{staticClass:"f2f-schedule-span"},[_vm._v("Select from the list")])]),_c('b-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"Chapter","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('f2f-select',{attrs:{"options":_vm.getAllChapters,"error":errors[0],"disabled":!_vm.classObj.course_module_key,"placeholder":"Chapter"},model:{value:(_vm.classObj.course_chapter_key),callback:function ($$v) {_vm.$set(_vm.classObj, "course_chapter_key", $$v)},expression:"classObj.course_chapter_key"}}),_c('span',{staticClass:"f2f-validation-text"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-row',{staticClass:"mb-4"},[_c('b-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"f2f-schedule-text"},[_vm._v("Select Unit")]),_c('div',{staticClass:"f2f-schedule-span"},[_vm._v("Select from the list")])]),_c('b-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"Unit","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('f2f-select',{attrs:{"placeholder":"Unit","options":_vm.getAllUnits,"error":errors[0],"disabled":!_vm.classObj.course_chapter_key},model:{value:(_vm.classObj.course_unit_key),callback:function ($$v) {_vm.$set(_vm.classObj, "course_unit_key", $$v)},expression:"classObj.course_unit_key"}}),_c('span',{staticClass:"f2f-validation-text"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1):_vm._e(),(!_vm.isEdit)?_c('b-row',{staticClass:"mb-3"},[_c('b-col',{attrs:{"cols":"6"}}),_c('b-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"float-right"},[_c('b-button',{staticClass:"cancelButton mr-4",attrs:{"variant":"link"},on:{"click":_vm.cancelClass}},[_vm._v("Cancel")]),_c('b-button',{attrs:{"variant":"success","disabled":invalid || _vm.submitted},on:{"click":_vm.handleSubmit}},[(_vm.submitted)?_c('b-spinner',{attrs:{"small":"","type":"grow"}}):_vm._e(),_vm._v(" Create ")],1)],1)])],1):_vm._e()],1)]}}])}),_c('f2f-modal',{attrs:{"display":_vm.confirmEdit,"type":"warning","title":"Are you sure you want to change the dates for this class?","body":"Editing class dates will affect previous settings like instructor assignment to session and all sessions\n    information that were previously created, those will be removed and new session will be generated"},on:{"hide":_vm.onEditConfirm}}),_c('f2f-modal',{attrs:{"display":_vm.onError,"type":"danger","title":_vm.errortitle,"body":_vm.error},on:{"hide":function($event){_vm.onError = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }